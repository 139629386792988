import Quill from 'quill';

const InlineBlot = Quill.import('blots/inline');

class FirstPersonError extends InlineBlot {
    static create() {
        const node = super.create();

        node.setAttribute('class', 'first-person-error');

        return node;
    }

    static formats() {
        return true;
    }
}

FirstPersonError.blotName = 'first-person-error';

FirstPersonError.tagName = 'span';

export default FirstPersonError;
